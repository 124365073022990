/* ----------------------Hello Screen screen css ----------------------- */
.HelloScreenContainer {
    background-image: url("../images/bg1.svg");
    background-position: center;
    /* Center the image */
    background-repeat: no-repeat;
    /* Do not repeat the image */
    background-size: cover;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
}

.HelloScreenHeader {
    padding: 8px 16px;
}

.HelloScreenLogo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 100px;
}

.HelloScreenContent {
    display: flex;
    flex-direction: column;
    padding: 0 24px;
}

.HelloScreenContentHeading {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 38px;
    margin: 0;
    color: #000000
}

.HelloScreenContentSubHeading {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    padding: 24px 0;
    color: #000000;
}

.HelloScreenContentNameInput {
    outline: none;
    background: #FAFAFA;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 13px 16px;
    margin: 12px 0;
}

.HelloScreenContentButton {
    background: #009AE0;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 13px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;

}

.HelloScreenDisable {
    background: #E5E5E5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 13px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
}





/* ----------------------------Splash Screen css----------------------------------- */

.SplashContainer {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background-image: url("../images/BackgroundSplash.svg");
    /* background-position: center; */
    /* Center the image */
    background-size: cover;
}


.SplashHeader {
    padding: 16px 8px 0px 8px;
    border: none;
    outline: none;
    background: inherit;
    display: flex;
}


.SplashContent {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    outline: none;
    border: none;
    background: inherit;
}

.SplashmyInstitute {
    padding: 160px 0 40px 0;
}

.SplashName {
    margin: 0px;
    color: #000000;
    display: flex;
    justify-content: center;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    color: #FFFFFF;
}


/* ------------------------------- Intro Screen css --------------------------------------- */

.IntroContainer {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: hidden;
    background-image: url("../images/BackgroundSplash.svg");
}

.IntroHeader {
    padding: 16px 8px 24px 8px;
    outline: none;
    border: none;
    background: inherit;
    display: flex;
}

.IntroMobileContainer {
    padding: 0 8px;
    position: relative;
}

.IntroMobileText {
    position: absolute;
    z-index: 1;
    left: 10%;
    top: 9.2%;
    display: flex;
    align-items: center;

}

.IntroMobile {
    width: 100%;


}

.IntroFooterContainer {
    display: flex;
    position: fixed;
    bottom: 0px;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0px -2px 4px rgba(154, 154, 154, 0.24);

}

.IntroTellMore {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #009AE0;
    padding: 16px 0;
    border: none;
    outline: none;
    background: #FFFFFF;
}

.IntroButton {
    background: #009AE0;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
    padding: 13px 0px;
    display: flex;
    justify-content: center;
    border: none;
    outline: none;
    margin: 24px;
}



/* ---------------------------- Growth Screen css ------------------------------------------- */

.GrowthContainer {
    background: #0AA859;
    background-image: url("../images/GrowthBackground.svg");
    background-position: center;
    /* Center the image */
    background-repeat: no-repeat;
    /* Do not repeat the image */
    background-size: cover;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
}

.GrowthInnerContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 100%;
}

.GrowthHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0 0 0;
}

.GrowthText {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 150%;
    display: flex;
    align-items: center;
    padding: 16px 24px;
    color: #FFFFFF;
    text-align: center;
}

.GrowthBackgroudImg {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 50vh;
    position: relative;
}

.GrowthDownload {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 12%;
    right: 1%;
}

.GrowthSubBg {
    /* margin-top: 20px; */
    width: 300px;
    display: flex;
    justify-content: center;
}

.GrowthContainerBody {

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 16px;
    text-align: center;
}

.GrowthBodyHeader {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 150%;
    color: #000000;
    /* margin-bottom: 20px; */
    margin-bottom: 24px
}

.GrowthDescription {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
    /* margin-bottom: 10px; */
    margin: 6px 0;

}

.GrowthContainerFooter {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    position: fixed;
    bottom: 32px;
    width: 100%;
    /* padding-top: 24px; */
}


.GrowthTellMore {
    background: rgba(114, 175, 175, 0.16);
    border-radius: 120px;
    outline: none;
    padding: 4px 10px;
    border: none;
    margin-right: 16px;
}

.GrowthBackButton {
    outline: none;
    background: inherit;
    border: none;
}


.GrowthBounce {
    -webkit-animation: GrowthBounce 2s infinite;
    animation: GrowthBounce 2s infinite;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;

}

@-webkit-keyframes GrowthBounce {

    0%,
    20%,
    40%,
    70%,
    80%,
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    50% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }

    60% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}

@keyframes GrowthBounce {

    0%,
    20%,
    40%,
    80%,
    100% {
        -ms-transform: translateY(0);
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    50% {
        -ms-transform: translateY(-30px);
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }

    60% {
        -ms-transform: translateY(-15px);
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }

    70% {
        -ms-transform: translateY(-30px);
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
}

@media(min-width:351px) and (max-width:365px) {
    .GrowthText {
        font-size: 30px;
    }
}

@media(max-width:350px) {

    .GrowthHeader {
        padding: 16px 0 0 0;
    }

    .GrowthBodyHeader {
        margin: 8px 0;
    }

    .GrowthText {
        font-size: 20px;
    }

    .GrowthDescription {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #000000;
        /* margin-bottom: 10px; */
        margin: 0;


    }



    .GrowthContainerFooter {
        bottom: 16px;
    }
}





/* -------------------------------Revenue Screen css ---------------------------------- */


.RevenueContainer {
    background: #C95E4C;

    background-image: url("../images/RevenueBackground.svg");
    background-position: center;
    /* Center the image */
    background-repeat: no-repeat;
    /* Do not repeat the image */
    background-size: cover;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
}

.RevenueInnerContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 100%;
}

.RevenueHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0 0 0;
}

.RevenueText {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 150%;
    display: flex;
    align-items: center;
    padding: 8px 24px;
    color: #FFFFFF;
    text-align: center;
}

.RevenueBackgroudImg {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    height: 50vh;
}

.RevenueLive {
    width: 50px;
    height: 50px;
    position: absolute;
    top: -30px;
}

.RevenueSubBg {
    /* margin-top: 20px; */
    width: 300px;
    display: flex;
    justify-content: center;
}

.RevenueContainerBody {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 16px;
    text-align: center;
}

.RevenueBodyHeader {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 150%;
    color: #000000;
    /* margin-bottom: 20px; */
    margin-bottom: 24px
}

.RevenueDescription {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
    /* margin-bottom: 10px; */
    margin: 6px 0;

}

.RevenueContainerFooter {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    position: fixed;
    bottom: 10px;
    /* padding-top: 24px; */
    width: 100%;
}

.RevenueTellMore {
    background: rgba(255, 114, 94, 0.16);
    border-radius: 120px;
    border-radius: 120px;
    outline: none;
    padding: 4px 10px;
    border: none;
    margin-right: 16px;
}

.RevenueBackButton {
    outline: none;
    background: inherit;
    border: none;
}



.RevenueBounce {
    -webkit-animation: RevenueBounce 2s infinite;
    animation: RevenueBounce 2s infinite;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;

}

@-webkit-keyframes RevenueBounce {

    0%,
    20%,
    40%,
    70%,
    80%,
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    50% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }

    60% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}

@keyframes RevenueBounce {

    0%,
    20%,
    40%,
    80%,
    100% {
        -ms-transform: translateY(0);
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    50% {
        -ms-transform: translateY(-30px);
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }

    60% {
        -ms-transform: translateY(-15px);
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }

    70% {
        -ms-transform: translateY(-30px);
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
}

@media(min-width:351px) and (max-width:365px) {
    .RevenueText {
        font-size: 30px;
    }
}

@media(max-width:350px) {

    .RevenueHeader {
        padding: 16px 0 0 0;
    }

    .RevenueBodyHeader {
        margin: 8px 0;
    }

    .RevenueText {
        font-size: 20px;
    }

    .RevenueDescription {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #000000;
        /* margin-bottom: 10px; */
        margin: 0;


    }


    .RevenueContainerFooter {
        bottom: 16px;
    }
}




/* -------------------------- Engagement Screen css---------------------------- */

.EngagementContainer {
    background: #D1559C;
    background-image: url("../images/EngagementBackground.svg");
    background-position: center;
    /* Center the image */
    background-repeat: no-repeat;
    /* Do not repeat the image */
    background-size: cover;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
}

.EngagementHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0 0 0;
}

.EngagementInnerContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 100%;
}

.EngagementBackgroudImg {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    height: 45vh;
    display: flex;
}

.EngagementMap {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 35%;
    left: 20%;
}

.EngagementText {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 150%;
    display: flex;
    align-items: center;
    padding: 8px 24px;
    color: #FFFFFF;
    text-align: center;
    margin: 0;
}

.EngagementSubBg {
    /* margin-top: 20px; */
    width: 300px;
    display: flex;
    justify-content: center;
}

.EngagementContainerBody {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 16px;
    text-align: center;
}

.EngagementBodyHeader {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 150%;
    color: #000000;
    /* margin-bottom: 20px; */
    margin-bottom: 24px
}

.EngagementDescription {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
    /* margin-bottom: 10px; */
    margin: 6px 0;

}

.EngagementContainerFooter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    position: fixed;
    bottom: 10px;
    /* padding-top: 24px; */
    width: 100%;
}


.EngagementTellMore {
    background: rgba(129, 181, 130, 0.16);
    border-radius: 120px;
    border-radius: 120px;
    outline: none;
    padding: 4px 10px;
    border: none;
    margin-right: 16px;
}

.EngagementBackButton {
    outline: none;
    background: inherit;
    border: none;
}



.EngagementBounce {
    -webkit-animation: EngagementBounce 2s infinite;
    animation: EngagementBounce 2s infinite;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;

}

@-webkit-keyframes EngagementBounce {

    0%,
    20%,
    40%,
    70%,
    80%,
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    50% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }

    60% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}

@keyframes EngagementBounce {

    0%,
    20%,
    40%,
    80%,
    100% {
        -ms-transform: translateY(0);
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    50% {
        -ms-transform: translateY(-30px);
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }

    60% {
        -ms-transform: translateY(-15px);
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }

    70% {
        -ms-transform: translateY(-30px);
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
}

@media(min-width:351px) and (max-width:365px) {
    .EngagementText {
        font-size: 30px;
    }
}



@media(max-width:350px) {

    .EngagementHeader {
        padding: 16px 0 0 0;
    }

    .EngagementBodyHeader {
        margin: 8px 0;
    }

    .EngagementText {
        font-size: 20px;
    }

    .EngagementDescription {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #000000;
        /* margin-bottom: 10px; */
        margin: 0;


    }


    .EngagementContainerFooter {
        bottom: 16px;
    }
}



/*------------------------- Teach Screen css ------------------------------------ */

.TeachContainer {
    background: #9584FF;
    background-image: url("../images/TeachBackground.svg");
    background-position: center;
    /* Center the image */
    background-repeat: no-repeat;
    /* Do not repeat the image */
    background-size: cover;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
}

.TeachInnerContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 100%;
}

.TeachHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0 0 0;
}

.TeachText {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 150%;
    display: flex;
    align-items: center;
    padding: 16px 24px 0 24px;
    color: #FFFFFF;
    text-align: center;

}

.TeachBackgroudImg {
    width: 100%;
    position: relative;
    height: 50vh;

}


.TeachIllustration {
    position: absolute;
    top: 20px;
    width: 100%;
    display: flex;

}

.TeachSubBg {
    /* margin-top: 20px; */
    width: 300px;
    display: flex;
    justify-content: center;
}

.TeachContainerBody {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 16px;
    text-align: center;
}

.TeachBodyHeader {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 150%;
    color: #000000;
    /* margin-bottom: 20px; */
    margin-bottom: 24px
}

.TeachDescription {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
    /* margin-bottom: 10px; */
    margin: 6px 0;

}

.TeachContainerFooter {
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    flex-direction: column;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    position: fixed;
    bottom: 10px;
    width: 100%;
    /* padding-top: 24px; */
}


.TeachTellMore {
    background: rgba(118, 155, 236, 0.16);
    border-radius: 120px;
    border-radius: 120px;
    outline: none;
    padding: 4px 10px;
    border: none;
    margin-right: 16px;
}

.TeachBackButton {
    outline: none;
    background: inherit;
    border: none;
}



.TeachBounce {
    -webkit-animation: TeachBounce 2s infinite;
    animation: TeachBounce 2s infinite;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;

}

@-webkit-keyframes TeachBounce {

    0%,
    20%,
    40%,
    70%,
    80%,
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    50% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }

    60% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}

@keyframes TeachBounce {

    0%,
    20%,
    40%,
    80%,
    100% {
        -ms-transform: translateY(0);
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    50% {
        -ms-transform: translateY(-30px);
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }

    60% {
        -ms-transform: translateY(-15px);
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }

    70% {
        -ms-transform: translateY(-30px);
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
}

@media(min-width:351px) and (max-width:365px) {
    .TeachText {
        font-size: 32px;
    }
}


@media(max-width:350px) {

    .TeachHeader {
        padding: 16px 0 0 0;
    }

    .TeachBodyHeader {
        margin: 8px 0;
    }

    .TeachText {
        font-size: 20px;
    }

    .TeachDescription {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #000000;
        /* margin-bottom: 10px; */
        margin: 0;


    }


    .TeachContainerFooter {
        bottom: 16px;
    }
}




/*------------------------- Brand Screen css ------------------------------------ */


.BrandContainer {
    background: #9584FF;
    background-image: url("../images/BrandBackground.svg");
    background-position: center;
    /* Center the image */
    background-repeat: no-repeat;
    /* Do not repeat the image */
    background-size: cover;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
}

.BrandInnerContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.BrandHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
}

.BrandBackgroudImg {
    width: 100%;
    display: flex;
    justify-content: center;
}

.BrandSubBg {
    /* margin-top: 20px; */
    width: 300px;
    display: flex;
    justify-content: center;
}


.BrandContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.BrandTopText {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 16px 0;
    color: #FFFFFF;
    margin: 0px;
}

.BrandSecondText {
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    display: flex;
    align-items: center;
    text-align: center;
    margin: 0px;
    color: #FFFFFF;
    padding-bottom: 16px;
}

.BrandLogo {
    margin-bottom: 32px;
}

.BrandVideoText {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    padding: 14px 0;
    display: flex;
    align-items: center;
    text-align: center;
    margin: 0;
    color: #FFFFFF;
}

.BrandExpert {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    margin: 0;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    width: 100%;
    text-decoration: underline
}

.BrandContainerFooter {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    position: fixed;
    bottom: 32px;
    width: 100%;
}


.BrandBackButton {
    outline: none;
    background: inherit;
    border: none;
}





.BrandBounce {
    -webkit-animation: BrandBounce 2s infinite;
    animation: BrandBounce 2s infinite;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;

}

@-webkit-keyframes BrandBounce {

    0%,
    20%,
    40%,
    70%,
    80%,
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    50% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }

    60% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}

@keyframes BrandBounce {

    0%,
    20%,
    40%,
    80%,
    100% {
        -ms-transform: translateY(0);
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    50% {
        -ms-transform: translateY(-30px);
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }

    60% {
        -ms-transform: translateY(-15px);
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }

    70% {
        -ms-transform: translateY(-30px);
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
}

@media(min-width:351px) and (max-width:365px) {
    .BrandContent {
        margin-top: 10px;

    }

    .BrandTopText {
        font-size: 18px;
        padding: 0px;
    }

    .BrandSecondText {
        font-size: 32px;

    }
}

@media(max-width:350px) {

    .BrandHeader {
        padding: 16px 0 0 0;
    }

    .BrandBodyHeader {
        margin: 8px 0;
    }

    .BrandContent {
        margin-top: 0px;

    }

    .BrandTopText {
        font-size: 12px;
        padding: 0px;
        line-height: 18px
    }

    .BrandSecondText {
        font-size: 16px;
        padding-bottom: 4px;
        line-height: 24px
    }

    .BrandLogo {
        margin-bottom: 8px;
        height: 25px;
    }

    .BrandVideoText {
        padding: 4px 0px;
    }

    .BrandContainerFooter {
        bottom: 16px;
    }
}






/*------------------------- Description Screen css ------------------------------------ */

.DescriptionContainer {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background-image: url("../images/bg1.svg");
    background-position: center;
    /* Center the image */
    background-size: cover;
}




.DescriptionHeader {
    padding: 16px 8px 0px 8px;
}

.DescriptionContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 60px;
    /* justify-content: center; */
    height: 100vh;
}

.DescriptionMyInstitute {
    padding: 102px 0 16px 0;

}

.DescriptionHeading {
    font-weight: bold;
    font-size: 24px;
    line-height: 150%;
    display: flex;
    align-items: center;
    text-align: center;
    margin: 0;
    color: rgba(0, 0, 0, 0.87);
}

.DescriptionSubHeading {
    padding: 32px 0 22px 0;
    font-weight: 500;
    font-size: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    margin: 0px;
    color: rgba(0, 0, 0, 0.87);
}

.DescriptionText {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 150%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
    display: flex;
    align-items: center;
    text-align: center;
    margin: 0;
    color: rgba(0, 0, 0, 0.87);
}


.DescriptionButton {
    background: #009AE0;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
    padding: 16px 0px;
    display: flex;
    justify-content: center;
    border: none;
    outline: none;
    margin: 40px 0 0 0;
    width: 85%;
}

@media(max-width:350px) {
    .DescriptionContent {
        padding-top: 10px
    }
}