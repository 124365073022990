body {
  margin: 0;
  font-family: "Circular Std", sans-serif;
}

/* 
@font-face {
  font-family: "Circular Std";
  src: url("./CircularStd-Black.otf") format("otf");
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}